h1 {
    font-size: 1.6rem;
}

h2 {
    font-size: 1.5rem;
}

h3 {
    font-size: 1.4rem;
}

h4 {
    font-size: 1.3rem;
}

h5 {
    font-size: 1.2rem;
}

a,
a:hover {
    color: #339933;
}

@font-face {
    font-family: 'fontello';
    src: url('./fontello.ttf?5acd608c') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.icons {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.newItem {
    padding-right: 5px;
}

.footerIcons {
    font-size: 150%;
    color: #B1B1B1;
}

.iconSelected {
    color: #8F9DFF
}

.navbar {
    padding: 0;
    line-height: 1em;
}

.navbar.bg-dark {
    background-color: #507619 !important;
}

.nav-link {
    margin-right: 1.5em;
    margin-right: 1.5em;
}

.navbar-nav {
    --bs-nav-link-padding-y: 0.3em;
}

.navbar-toggler-outer {
    display: flow-root;
    width: 100%;
}


.navbar-toggler {
    border: 0;
    float: right;
}

body {
    font-size: 1rem;
    line-height: 1.4;
}

.content {
    margin-top: 3em;
}

.center {
    text-align: center;
}

.me-auto {
    margin: 0 auto;
}

.me-auto a.nav-link {
    color: white;
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
    color: white;
    font-weight: bold;
}

button.custom-btn {
    font-size: 0.85em !important;
    margin: 0 0.2em 0.3em;
    padding: 0.2em 0.7em !important;
}

input {
    border-color: gray !important;
}

.form-group input.form-check-input {
    margin: 27px 10px 0 0;
}

.row {
    margin-bottom: 0.5em;
}

#userName {
    text-align: center;
}

#outerContainer {
    width: 75%;
}

#outerContainer.shopping {
    width: 100%;
}

.list-group,
.shoppingFirstItem {
    padding-top: 0.5em;
}

.shopping .list-group-item {
    font-size: 1.3em;
    padding: 0.3em 1em;
}

.shopping .navbar {
    background-color: #03a9f4;
    font-size: 1.5em;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 2em;
    z-index: 2;
}

.shopping .category {
    font-weight: bold;
    background-color: #dbe6eb;
}

.shopping .hide {
    display: none !important;
}

.shopping .navLeftRightIcons {
    width: 3.9em;
    padding: 0 0.3em;
}

.alignLeft {
    text-align: left;
}

.alignRight {
    text-align: right;
}

.shopping .navIcons {
    margin: 0 auto;
}

.shopping .icons {
    margin: 0 0.3em;
    cursor: pointer;
}

.shopping .navIcons .icons.iconSelected {
    color: #2236c6;
}

.shopping button {
    margin: 1.5em 0.3em;
}

.shopping .strikeOut {
    background-color: #ffa3b3;
}

.shopping .basketItem {
    background-color: rgba(55, 234, 55, 0.54);
}

.shopping .edit {
    border: 0;
}

.shopping .edit .icons {
    padding: 0 0.3em 0 1em;
}

.shopping .flexfill {
    flex-grow: 1;
}

.animate-spin {
    -moz-animation: spin 2s infinite linear;
    -o-animation: spin 2s infinite linear;
    -webkit-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
    display: inline-block;
    font-size: 0.8em;
}

img.flag {
    width: 80px
}

@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@-webkit-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@-o-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@-ms-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}


#bottom {
    clear: both;
    margin: 10px;
    text-align: center;
    font-size: x-small;
    padding-right: 5px;
    padding-bottom: 15px;
}

.photoCategory {
    margin: 0 .5em .5em 1.5em;
    width: 180px;
    display: inline-block;
    height: 210px;
}

.photoCategoryLink {
    border: 0;
    background-color: initial;
    width: 170px;
}



.img-shadow img {
    width: 160px;
    height: 160px;
}

@media (max-width: 991px) {
    #topImg {
        display: none;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-wrap: wrap;
    }
}

@media (max-width: 768px) {
    #outerContainer {
        width: 100%;
    }

    .row>* {
        padding-right: 0;
    }
}